#App {
  text-align: center;
  height: 100vh;
  overflow-x: hidden;

}

.MuiMenu-paper {
  background-color: whitesmoke;
  color: darkblue;
}

.bg-color-red {
  background-color: #f50057;
}

.color-red {
  color: #f50057;

}
.color-blue {
  color: rgb(11, 11, 109);
 

}
.color-green {
  color: darkgreen;
  font-weight: bold;
}

.App-logo {
  height: 40vmin;
}


#App-header {
  background-color: #282c34;
  min-height: 8vh;

  font-size: calc(10px + 2vmin);

  color: white;
}

#App-body {
  min-height: 73vh;
  overflow-x: hidden;
  text-align: left;
  background-color: whitesmoke;
  overflow: hidden;
 /* padding: 12px; */
}

#App-bar-wrapper {
  height: 6vh;
  display: flex;
  flex-direction: column;
  align-items: left;


  font-size: calc(6px + 2vmin);
  text-align: left;
}

#App-footer {
  min-height: 8vh;
  background-color:  #282c34;
  color: white;
  /*display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  */
  font-size: calc(10px + 2vmin);
 
  padding: 0 16px 0 16px;
}

.App-link {
  color: #09d3ac;
}
.Mui-selected {
  color: white !important;

}

.MuiDataGrid-row.Mui-selected {
  background-color: lightskyblue !important;
  color: black !important;
}

.MuiDialog-paper {
  background-color: whitesmoke;
}

.fc-list-empty {
  align-items: flex-start !important;
}
